<template lang="">
    <div class="mobile">
        <h3>互动投影（墙面版）</h3>
        <div class="desc">
            <p>
                互动投影是使用图形化编程语言结合激光雷达实现的超大平面触控科普创客项目，可以在数平方米的平面上投射影像并支持多人互动，营造极具冲击力的沉浸式体验。
            </p>
            <p>
                本平台无法在手机端运行，请在电脑上打开本平台以获得最佳体验。还存在其他问题请点击「聊一聊」联系产品经理。
            </p>
        </div>
        <div class="equipment">
            <h3 class="title">相关硬件设备</h3>
            <div class="list">
                <p>1. 显示器</p>
                <p>2. 迷你主机</p>
                <p>3. 超短焦投影仪</p>
                <p>4. 一堵空白墙面</p>
                <p>5. 配套外设（激光雷达、AI 摄像头等）</p>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, reactive } from 'vue';
import store from '@/store';
const playerContainerId = ref(null);
export default {
    name: 'MobilePage',
    setup() {
        const player = ref(null);
        return {
            playerContainerId,
            player,
        };
    },
};
</script>
<style lang="scss" scoped>
.mobile {
    height: calc(100vh - 18.4vw);
    background: #fff;
    padding: 8.26vw 9.833vw 20vw;
    font-size: 3.467vw;
    color: #666666;
    font-family: PingFangSC, PingFang SC;
    h3 {
        font-weight: 600;
        font-size: 4.8vw;
        color: #333333;
        margin-bottom: 4.8vw;
        text-align: center;
    }
    .desc {
        p {
            line-height: 5.3vw;
            margin-bottom: 5.4vw;
            text-align: justify;
        }
    }
    .equipment {
        margin-top: 8vw;
        .list {
            p {
                margin-bottom: 6vw;
            }
        }
    }
    .course {
        .image {
            position: relative;
            width: 100%;
            height: 44.533vw;
            border-radius: 1.6vw;
            overflow: hidden;
            background: #000;
            margin-bottom: 4.533vw;
            .poster {
                width: 100%;
                height: 100%;
            }
            .mask {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgb(66 66 66 / 19%);
                .playIcon {
                    width: 20.533vw;
                }
            }
        }
    }
}
.video-box {
    width: 91vw;
    height: 51.2vw;
    margin: 0 auto;
}
</style>
