<template>
    <div class="login">
        <BrowserTips />
        <a-spin size="large" :spinning="loading">
            <div class="login-form">
                <h3>互动投影（墙面版）</h3>
                <a-form
                    :model="formState"
                    name="basic"
                    :label-col="{ span: 0 }"
                    :wrapper-col="{ span: 24 }"
                    autocomplete="off"
                    @finish="onFinish"
                    @finishFailed="onFinishFailed"
                    class="form"
                >
                    <a-form-item
                        name="store_no"
                        :rules="[
                            {
                                required: true,
                                message: '请输入门店 ID',
                            },
                        ]"
                    >
                        <a-input
                            v-model:value="formState.store_no"
                            placeholder="门店 ID"
                            :style="{
                                height: '40px',
                                padding: '4px 14px',
                                borderRadius: '4px',
                            }"
                        />
                    </a-form-item>

                    <a-form-item
                        name="store_password"
                        :rules="[
                            {
                                required: true,
                                message: '请输入密码',
                            },
                        ]"
                    >
                        <a-input-password
                            placeholder="密码"
                            v-model:value="formState.store_password"
                            :style="{
                                height: '40px',
                                padding: '4px 14px',
                                borderRadius: '4px',
                            }"
                        />
                    </a-form-item>

                    <a-form-item
                        :wrapper-col="{
                            offset: 0,
                            span: 24,
                        }"
                    >
                        <a-button
                            type="primary"
                            html-type="submit"
                            :style="{
                                width: '100%',
                                height: '40px',
                                borderRadius: '4px',
                            }"
                            :disabled="isAgree"
                            >登录</a-button
                        >
                    </a-form-item>
                    <p>忘记密码时请联系英荔客服专员</p>
                </a-form>

                <div class="agree">
                    <a-checkbox v-model:checked="formState.agree"></a-checkbox>
                    <span
                        >本平台仅供英荔教育集团内部使用。为确保正常运行，会调用计算机的网络适配器、扬声器、麦克风、摄像头、蓝牙设备等并收集相关使用信息。</span
                    >
                </div>
            </div>
        </a-spin>
        <video ref="video" autoplay id="video"></video>
        <canvas ref="canvas" id="canvas"></canvas>
    </div>
</template>

<script lang="ts">
interface FormState {
    store_no: string;
    store_password: string;
    login_type?: number;
    agree?: boolean;
}

import { defineComponent, reactive, ref, watch } from 'vue';
import Cookies from 'js-cookie';
import { storeLogin, deviceLoginLog } from '@/api/index';
import store from '@/store';
import { message } from 'ant-design-vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { getBroswerName } from '@/utils/index';

import BrowserTips from '@/components/BrowserTips.vue';
const loginResMap: any = {
    203: '门店 ID 或密码错误',
    205: 'session 不存在',
    403: '您无权登录',
    207: '当前校区还未开通互动投影墙面版哦，请校长联系英荔教育销售人员申请采购',
};

export default defineComponent({
    name: 'LoginView',
    components: {
        BrowserTips,
    },
    setup() {
        // 判断是否为Chrome 或 Edge
        const route = useRoute();
        // Cookies.remove('radarToken',{ domain: '.aimaker.space' })
        // Cookies.remove('radarToken',{ domain: '.eliteu.xyz' })
        // Cookies.remove('radarToken',{ domain: 'localhost' })
        const loading = ref<boolean>(false);
        const formState = reactive<FormState>({
            store_no: '',
            store_password: '',
            agree: true,
        });

        const getDeviceName = () => {
            const userAgent = navigator.userAgent || navigator.vendor;
            if (
                userAgent.match(/iPad/i) ||
                userAgent.match(/iPhone/i) ||
                userAgent.match(/iPod/i)
            ) {
                return 'iOS';
            } else if (userAgent.match(/Android/i)) {
                return 'Android';
            } else if (userAgent.match(/webOS/i) || userAgent.match(/Palm/i)) {
                return 'Palm';
            } else if (userAgent.match(/BlackBerry/i)) {
                return 'BlackBerry';
            } else if (userAgent.match(/Windows Phone/i)) {
                return 'Windows Phone';
            } else if (userAgent.match(/SymbianOS/i)) {
                return 'Symbian';
            } else if (
                userAgent.match(/Tablet/i) &&
                !userAgent.match(/iPad/i)
            ) {
                return 'Tablet';
            } else if (
                userAgent.match(/Mobile/i) &&
                !userAgent.match(/iPhone/i) &&
                !userAgent.match(/Android/i)
            ) {
                return 'Mobile';
            } else {
                return 'Desktop';
            }
        };

        const setMultipleCookies = (obj: any) => {
            for (const k in obj) {
                Cookies.set(k, obj[k], {
                    domain: '.eliteu.xyz',
                    expires: 1,
                });
                Cookies.set(k, obj[k], {
                    domain: '.aimaker.space',
                    expires: 1,
                });
                Cookies.set(k, obj[k], {
                    domain: 'localhost',
                    expires: 1,
                });
            }
        };
        const onFinish = (values: any) => {
            values.login_type = 2;
            loading.value = true;
            storeLogin(values).then(async res => {
                console.log('res', res);
                const code = res.data.code as any;
                const content = loginResMap[code];
                const token = res.data.store_token;
                if (content) {
                    loading.value = false;
                    message.error(content);
                    return;
                }
                const store_id = res.data.store_id;
                const name = res.data.name;
                store.commit('updateInfo', res.data);
                sessionStorage.setItem('isLogin', 'true');
                sessionStorage.setItem('store_token', token);
                setMultipleCookies({
                    radarToken: token,
                    elite_store_id: store_id,
                    elite_store_name: name,
                });
                await deviceLoginLog({
                    platform_type: 'Radar',
                    device_name: getDeviceName(),
                    operating_system: navigator.platform.toLowerCase(),
                    browser: getBroswerName(),
                });
                loading.value = false;
                if (route.query.redirect) {
                    window.location.replace(route.query.redirect.toString());
                } else {
                    await router.replace('/');
                }
            });
        };
        const onFinishFailed = (errorInfo: any) => {
            console.log('Failed:', errorInfo);
        };
        const isAgree = ref(false);
        watch(
            () => formState.agree,
            val => {
                isAgree.value = !val;
            }
        );
        return {
            formState,
            isAgree,
            loading,
            onFinish,
            onFinishFailed,
        };
    },
});
</script>
<style lang="scss" scoped>
.login {
    ::v-deep .ant-spin-container {
        height: calc(100vh - 136px) !important;
    }
    .login-form {
        width: 410px;
        height: fit-content;
        background: #ffffff;
        border-radius: 10px;
        padding: 32px 0 37px;
        font-size: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;

        h3 {
            font-weight: 600;
            font-size: 28px;
            line-height: 38px;
            color: #333333;
            margin-bottom: 27px;
            text-align: center;
        }

        p {
            margin-top: -10px;
            margin-bottom: 18px;
            text-align: right;
            color: #999999;
        }

        .form {
            margin: 0 40px;
        }

        .agree {
            display: flex;
            align-items: flex-start;
            color: #999999;
            font-size: 14px;
            margin: 0 32px;
            line-height: 22px;

            span {
                margin-left: 3px;
                text-align: justify;
            }
        }
    }

    #canvas,
    #video {
        opacity: 0;
        position: fixed;
    }
}
</style>
