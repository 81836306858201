<template>
    <a-modal
        :open="!isChromeOrEdge"
        :title="false"
        centered
        :footer="null"
        :maskClosable="false"
        :closable="false"
        width="473px"
    >
        <div class="browser-tips">
            <div class="tip">
                互动投影（墙面版）仅限使用特定的浏览器打开，<br />请点击下方的浏览器，下载安装
            </div>
            <div class="browser-list">
                <div
                    v-for="browser in browserList"
                    :key="browser.name"
                    class="browser"
                >
                    <a :href="browser.url" target="_blank">
                        <img :src="browser.img" :alt="browser.name" />
                        <div class="name">
                            <span>{{ browser.name }}</span>
                        </div>
                        <div class="desc">{{ browser.desc }}</div>
                    </a>
                </div>
            </div>
        </div>
    </a-modal>
</template>
<script setup lang="ts">
const chromeImg = require('@/assets/images/Chrome.png');
import { BrowserType } from '@/utils/index';
import { ref, reactive } from 'vue';
const isChromeOrEdge = ref(false);
const browserList = reactive([
    {
        img: chromeImg,
        name: '谷歌 Chrome 浏览器',
        desc: '（首选，适合几乎所有网页）',
        url: 'https://www.google.cn/intl/zh-CN/chrome/',
    },
]);
const broswerRes = BrowserType();
if (broswerRes.supporterVs) {
    if (
        broswerRes.supporter === 'chrome' &&
        Number(broswerRes.supporterVs.split('.')[0]) >= 76
    ) {
        isChromeOrEdge.value = true;
    } else {
        isChromeOrEdge.value = false;
    }
}
</script>
<style lang="scss" scoped>
.browser-tips {
    width: 100%;
    text-align: center !important;
    padding: 20px 10px;
    font-size: 16px;
    .tip {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        text-align: center !important;
        margin-bottom: 22px;
    }
    .browser-list {
        display: flex;
        justify-content: center;
        .browser {
            margin: 0 30px;
            text-align: center;
            img {
                width: 97px;
                height: 97px;
                object-fit: contain;
                margin-bottom: 12px;
            }
            .name {
                margin-bottom: 4px;
                color: var(--ifm-link-color) !important;
                font-weight: 500;
            }
            .desc {
                font-size: 14px;
                color: #999999;
            }
        }
    }
}
</style>
