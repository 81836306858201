import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "browser-tips" }
const _hoisted_2 = { class: "browser-list" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "desc" }

import { BrowserType } from '@/utils/index';
import { ref, reactive } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'BrowserTips',
  setup(__props) {

const chromeImg = require('@/assets/images/Chrome.png');
const isChromeOrEdge = ref(false);
const browserList = reactive([
    {
        img: chromeImg,
        name: '谷歌 Chrome 浏览器',
        desc: '（首选，适合几乎所有网页）',
        url: 'https://www.google.cn/intl/zh-CN/chrome/',
    },
]);
const broswerRes = BrowserType();
if (broswerRes.supporterVs) {
    if (
        broswerRes.supporter === 'chrome' &&
        Number(broswerRes.supporterVs.split('.')[0]) >= 76
    ) {
        isChromeOrEdge.value = true;
    } else {
        isChromeOrEdge.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    open: !isChromeOrEdge.value,
    title: false,
    centered: "",
    footer: null,
    maskClosable: false,
    closable: false,
    width: "473px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tip" }, [
          _createTextVNode(" 互动投影（墙面版）仅限使用特定的浏览器打开，"),
          _createElementVNode("br"),
          _createTextVNode("请点击下方的浏览器，下载安装 ")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(browserList, (browser) => {
            return (_openBlock(), _createElementBlock("div", {
              key: browser.name,
              class: "browser"
            }, [
              _createElementVNode("a", {
                href: browser.url,
                target: "_blank"
              }, [
                _createElementVNode("img", {
                  src: browser.img,
                  alt: browser.name
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(browser.name), 1)
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(browser.desc), 1)
              ], 8, _hoisted_3)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})