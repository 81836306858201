import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bottom"
}
const _hoisted_2 = {
  key: 0,
  class: "bottom-upper"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "bottom-version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EliteuServiceVue3 = _resolveComponent("EliteuServiceVue3")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      _createVNode(_component_Header),
      _createVNode(_component_router_view),
      (_ctx.showBottomTip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.isLogin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.isCoursePage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_a_tooltip, {
                          title: "首次使用这个产品吗？先看看教程吧",
                          open: _ctx.isFirst,
                          getPopupContainer: _ctx.getPopupContainer
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              class: "bottom-text",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLink(_ctx.themeInfo.video_url))),
                              target: "_blank"
                            }, _toDisplayString(_ctx.themeInfo.video_name), 1)
                          ]),
                          _: 1
                        }, 8, ["open", "getPopupContainer"]),
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "bottom-line" }, null, -1))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_4, "产品版本：" + _toDisplayString(_ctx.version), 1)
                ]))
              : _createCommentVNode("", true),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bottom-lower" }, " 互动投影是使用图形化编程语言结合激光雷达实现的超大平面触控体验创客项目，可以在数平方米的平面上投射影像并支持多人互动，营造极具冲击力的沉浸式体验 ", -1))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_EliteuServiceVue3)
    ]),
    _: 1
  }, 8, ["locale"]))
}