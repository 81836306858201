import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { class: "agree" }
const _hoisted_4 = {
  ref: "video",
  autoplay: "",
  id: "video"
}
const _hoisted_5 = {
  ref: "canvas",
  id: "canvas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrowserTips = _resolveComponent("BrowserTips")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BrowserTips),
    _createVNode(_component_a_spin, {
      size: "large",
      spinning: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("h3", null, "互动投影（墙面版）", -1)),
          _createVNode(_component_a_form, {
            model: _ctx.formState,
            name: "basic",
            "label-col": { span: 0 },
            "wrapper-col": { span: 24 },
            autocomplete: "off",
            onFinish: _ctx.onFinish,
            onFinishFailed: _ctx.onFinishFailed,
            class: "form"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                name: "store_no",
                rules: [
                            {
                                required: true,
                                message: '请输入门店 ID',
                            },
                        ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.store_no,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.store_no) = $event)),
                    placeholder: "门店 ID",
                    style: {
                                height: '40px',
                                padding: '4px 14px',
                                borderRadius: '4px',
                            }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                name: "store_password",
                rules: [
                            {
                                required: true,
                                message: '请输入密码',
                            },
                        ]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_password, {
                    placeholder: "密码",
                    value: _ctx.formState.store_password,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.store_password) = $event)),
                    style: {
                                height: '40px',
                                padding: '4px 14px',
                                borderRadius: '4px',
                            }
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { "wrapper-col": {
                            offset: 0,
                            span: 24,
                        } }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    "html-type": "submit",
                    style: {
                                width: '100%',
                                height: '40px',
                                borderRadius: '4px',
                            },
                    disabled: _ctx.isAgree
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("登录")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              _cache[4] || (_cache[4] = _createElementVNode("p", null, "忘记密码时请联系英荔客服专员", -1))
            ]),
            _: 1
          }, 8, ["model", "onFinish", "onFinishFailed"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.formState.agree,
              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.agree) = $event))
            }, null, 8, ["checked"]),
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "本平台仅供英荔教育集团内部使用。为确保正常运行，会调用计算机的网络适配器、扬声器、麦克风、摄像头、蓝牙设备等并收集相关使用信息。", -1))
          ])
        ])
      ]),
      _: 1
    }, 8, ["spinning"]),
    _createElementVNode("video", _hoisted_4, null, 512),
    _createElementVNode("canvas", _hoisted_5, null, 512)
  ]))
}