import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Theaters from '../views/Theaters.vue'
import Course from '../views/Course.vue'
import Login from '../views/Login.vue'
import Mobile from '../views/Mobile.vue'
import Distinguish from '../views/Distinguish.vue'
import Cookies from "js-cookie";
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'theaters',
    component: Theaters,
  },
  {
    path: '/course',
    name: 'course',
    component: Course
  },
  {
    path: '/identify',
    name: 'identify',
    component: Distinguish
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: Mobile
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLogin = sessionStorage.getItem('isLogin')
  if ((!isLogin) && (!to.path.includes('/identify'))) {
    store.commit('updateInfo', {
      store_id: null,
      name: '',
      short_name: ''
    })
    store.commit('updateThemeInfo', {
      id: null,
      game_store: null,
      name: null,
      image_url: null,
      manual_url: null,
      video_url: null,
      work_url: null
    })
  }
  if (!store.state.campusInfo.store_id) {
    if (to.name === 'mobile') {
      next()
      return
    }
    if (to.path.includes('/login')) {
      next()
    } else if (to.path.includes('/identify')) {
      // 从缓存中获取
      if (Cookies.get('radarToken')) {
        next()
      } else {
        next({
          path: "/login"
        })
      }
    } else {
      next({
        path: "/login"
      })
    }
  } else {
    if (to.path.includes('/login')) {
      next({
        path: "/"
      })
    } else {
      next()
    }
  }
})

export default router
