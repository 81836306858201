<template lang="">
    <div class="course-item">
        <span class="sort"> {{ index + 1 }}. </span>
        <div class="course-detail">
            <div class="title" v-html="title"></div>
            <div class="desc" v-text="desc"></div>
            <div class="imgs">
                <div>
                    <a-image-preview-group>
                        <a-image
                            :src="img"
                            :width="200"
                            :height="151"
                            alt=""
                            v-for="(img, imgIndex) in imgs"
                            :key="imgIndex"
                        />
                        <div class="tips" v-html="tips"></div>
                    </a-image-preview-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    name: 'CourseItem',
    props: {
        title: String,
        desc: String,
        tips: {
            type: String,
            default: '',
        },
        imgs: Array,
        index: Number,
        curNav: Number,
    },
    setup() {
        const activeImg = ref(store.state.courseImg);
        watch(
            () => store.state.courseImg,
            val => {
                activeImg.value = val;
            }
        );
        return {
            activeImg,
        };
    },
});
</script>
<style lang="scss">
.course-item {
    display: flex;
    margin-bottom: 20px;
    a {
        color: blue;
    }
    .sort {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
    }

    .course-detail {
        .title {
            font-size: 16px;
            color: #333333;
            margin-bottom: 6px;
            font-weight: 600;

            a {
                color: var(--font-color-link);
            }
        }

        .desc {
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            margin-bottom: 10px;
        }
        .imgs {
            display: flex;

            > div {
                position: relative;
            }
            .ant-image {
                width: 210px;
                height: 158px;
                object-fit: cover;
                margin-right: 16px;
                cursor: pointer;
                transition: all 0.3s;
                border-radius: 5px;
                overflow: hidden;
            }
        }
        .absolute {
            position: absolute;
            top: 0px;
        }
        .enlarge {
            position: fixed;
            width: 500px !important;
            height: auto !important;
            top: 30% !important;
            left: 40% !important;
            z-index: 2;
        }
    }
}
</style>
