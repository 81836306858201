<template lang="">
    <div class="course container">
        <h3>{{ campusInfo.name }} - {{ themeInfo.name }}</h3>
        <div class="title">
            <a-steps
                progress-dot
                :current="curNav"
                size="small"
                :items="titles.map(m => ({ title: m }))"
                @change="updateNav"
            ></a-steps>
        </div>
        <div>
            <div v-for="(course, index) in courseList[curNav]" :key="index">
                <CourseItem
                    :title="course.title"
                    :desc="course.desc"
                    :imgs="course.imgs"
                    :index="index"
                    :tips="course.tips"
                    :curNav="curNav"
                />
            </div>
        </div>
        <div class="complete" v-if="curNav < 3" @click="updateNav(curNav + 1)">
            我完成了，去下一步
        </div>
    </div>
</template>
<script>
// 接口请求回来的link
// 数组第几个的title = ''

import { ref, reactive, watch, onUnmounted } from 'vue';
import CourseItem from '@/components/CourseItem.vue';
import { updateCourse } from '@/utils/course';
import store from '@/store';
export default {
    name: 'CoursePage',
    components: { CourseItem },
    setup() {
        const curNav = ref(0);
        const campusInfo = reactive(store.state.campusInfo);
        const themeInfo = reactive(store.state.themeInfo);
        const courseList = ref(null);
        const titles = ref([
            '开启相关硬件',
            '启动基础软件',
            '打开剧场程序',
            '打开识别涂色卡页',
        ]);
        const updateNav = index => {
            curNav.value = index;
        };
        watch(
            store.state.themeInfo,
            (val, old) => {
                if (val.name) {
                    courseList.value = updateCourse(val.name, val);
                }
            },
            {
                immediate: true,
            }
        );
        onUnmounted(() => {
            store.commit('updateThemeInfo', {
                id: null,
                game_store: null,
                name: null,
                image_url: null,
                manual_url: null,
                video_url: null,
                work_url: null,
            });
        });
        return {
            curNav,
            titles,
            courseList,
            updateNav,
            campusInfo,
            themeInfo,
        };
    },
};
</script>
<style lang="scss" scoped>
.course {
    padding-bottom: 138px;
    h3 {
        font-weight: 600;
        font-size: 28px;
        color: #333;
        text-align: center;
        margin-top: 42px;
        margin-bottom: 0;
    }
    .title {
        width: 65%;
        margin: 46px auto 33px;
        .active {
            color: pink;
        }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-icon, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        //     width: 12px;
        //     height: 12px;
        //     box-sizing: content-box;
        //     // .ant-steps-icon-dot{

        //     // border: 3px solid #fff
        //     // }
        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot{
        //     border: 2px solid #fff;
        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-tail::after, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
        //     height: 2px;

        // }
        // :deep .ant-steps.ant-steps-dot .ant-steps-item-tail, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail{
        //     top: 5px;
        // }
        :deep(.ant-steps .ant-steps-item-icon .ant-steps-icon) {
            top: -2px;
        }
    }
    .complete {
        width: fit-content;
        color: #fff;
        background: var(--font-color-link);
        border-radius: 4px;
        padding: 11px 38px 10px;
        margin: 30px auto 0;
        cursor: pointer;
    }
}
</style>
<style lang="scss">
.course {
    .course-tips {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        text-align: left;
        font-style: normal;
    }
    .course-click {
        cursor: pointer;
        color: rgba(0, 132, 255, 1);
    }
}
</style>
