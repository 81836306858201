import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    campusInfo: {
      store_id: null,
      name: '',
      short_name: '',
    },
    courseImg: null,
    themeInfo: {
      id: null,
      game_store: null,
      name: null,
      image_url: null,
      manual_url: null,
      video_url: null,
      work_url: null,
      video_name: ''
    }
  },
  getters: {
  },
  mutations: {
    updateInfo(state, payload) {
      state.campusInfo = payload
    },
    updateThemeInfo(state, payload) {
      state.themeInfo = payload
    }
  },
  actions: {
    // logout({ commit }) {
    //   commit('logout')
    // }
  },
  modules: {
  }
})
