
import p1 from '@/assets/images/seabed/1.png';
import p2 from '@/assets/images/seabed/2.png';
import p3 from '@/assets/images/seabed/3.png';
import p4 from '@/assets/images/seabed/4.png';
import p5 from '@/assets/images/seabed/5.png';
import p6 from '@/assets/images/seabed/6.png';
import p7 from '@/assets/images/seabed/7.png';
import p8 from '@/assets/images/seabed/8.png';
import p9 from '@/assets/images/seabed/9.png';
import p10 from '@/assets/images/seabed/10.png';
import p11 from '@/assets/images/seabed/11.png';
import p12 from '@/assets/images/seabed/12.png';
import p13 from '@/assets/images/seabed/13.png';
import p14 from '@/assets/images/seabed/14.png';
import p15 from '@/assets/images/seabed/15.png';
import p16 from '@/assets/images/seabed/16.png';
import p17 from '@/assets/images/seabed/17.png';
import p18 from '@/assets/images/seabed/18.png';
import p19 from '@/assets/images/seabed/19.png';
import p20 from '@/assets/images/seabed/20.png';
import p21 from '@/assets/images/seabed/21.png';
import p22 from '@/assets/images/seabed/22.png';
import p23 from '@/assets/images/seabed/23.png';
import p24 from '@/assets/images/seabed/24.png';
import p25 from '@/assets/images/seabed/25.png';

import p13_os from '@/assets/images/outerSpace/13.png';
import p14_os from '@/assets/images/outerSpace/14.png';
import p15_os from '@/assets/images/outerSpace/15.png';
import p17_os from '@/assets/images/outerSpace/17.png';
import p18_os from '@/assets/images/outerSpace/18.png';
import p19_os from '@/assets/images/outerSpace/19.png';
import p20_os from '@/assets/images/outerSpace/20.png';
import p21_os from '@/assets/images/outerSpace/21.png';
import p25_os from '@/assets/images/outerSpace/25.png';

type ThemeInfo = {
    work_url: string
    work_name: string
    manual_url: string
    manual_name: string
    video_name: string
    id: string,
    game_store: string,
    name: string,
    image_url: string,
    video_url: string,
}

let themeInfo: ThemeInfo = {
    work_url: '',
    work_name: '',
    manual_url: '',
    manual_name: '',
    video_name: '',
    id: '',
    game_store: '',
    name: '',
    image_url: '',
    video_url: '',
}



// 更新字段函数
export const updateCourse = (name: string, data: ThemeInfo) => {
    themeInfo = data;
    const courseWriting: any = {
        "海底世界互动剧场": [
            [
                {
                    title: '打开高拍仪并点击按钮，调整到最大亮度。',
                    desc: '',
                    imgs: [p1],
                },
                {
                    title: '确保激光雷达插座开关、音响主机都处于开启状态（日常可以不用关）。',
                    desc: '',
                    imgs: [p2],
                },
                {
                    title: '用遥控器打开投影仪。按「Windows 徽标键 田+P」将投影模式设为「扩展」，直到在墙面上可以看到系统桌面的画面。',
                    desc: '',
                    imgs: [p3, p4, p5],
                },
            ],
            [
                {
                    title: '打开英荔自研工具<a href="adapterOpen://" target="_blank">「英荔 Adapter」</a>，开启后图标会显示在任务栏，而不会弹出任何界面。',
                    desc: '',
                    tips: `<div class="course-tips">
                            <div>点击上方的蓝色文字，Adapter 没有启动？可能是因为本机没有安装最新版的 Adapter，点击
                                <a href="https://ecourse.aimaker.space/%E6%95%99%E5%AD%A6%E8%BD%AF%E4%BB%B6%E7%AE%A1%E7%90%86/%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/Adapter_V2.3.0.exe" class="course-click">下载</a>。</div>
                            <div>互动投影目前只支持在 Windows10 1809 以上操作系统中运行，不会安装可以点击
                                <a target="_blank" href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/03b9a41f1397757896759347405/v.f40672.mp4" class="course-click">查看教程</a>。</div>
                        </div>`,
                    imgs: [p6, p7],
                },
                {
                    title: '打开激光雷达软件「雷达模拟鼠标」，待开启完成后（一般会自动最小化）点开其主界面。',
                    desc: '',
                    imgs: [p8, p9, p10],
                },
                {
                    title: '此时触摸墙面，可以在激光雷达软件界面上看到相应反馈（显示你触碰的位置）。',
                    desc: '',
                    imgs: [p11, p12],
                },
            ],
            [
                {
                    title: `点击打开<a href="${themeInfo.work_url}" target="_blank">${themeInfo.work_name}</a>页面，待其加载完成，然后把作品标签页（一般是向右）拖动到墙上投影仪投出的画面中。`,
                    desc: '预计网页加载时间在 1 分钟以上，请耐心等待。',
                    imgs: [p13, p14, p15, p16],
                },

                {
                    title: '把浏览器设为全屏，然后把互动剧场作品设为全屏。',
                    desc: '',
                    imgs: [p17, p18, p19],
                },
                {
                    title: '点击绿旗，运行互动剧场作品（此时界面不会有任何反应，只是完成了初始化）。',
                    desc: '',
                    imgs: [p20],
                },
            ],
            [
                {
                    title: `点击打开<a href="${window.location.origin}/identify" target="_blank"> AI 识别涂色卡</a>页面。`,
                    desc: '需要授权允许网页使用摄像头的权限。',
                    imgs: [p24],
                },
                {
                    title: '从柜子里拿出涂色卡备用。',
                    desc: '涂色卡成本较高，建议每次不要拿太多涂色卡出来，避免损坏或遗失。每张涂色卡都有唯一编码，无法复印使用。如涂色卡即将用尽请联系英荔集团销售人员申请采购。',
                    imgs: [p25],
                },
                {
                    title: `首次使用，或是遇到了奇怪的问题可以看一下<a href="${themeInfo.manual_url}" target="_blank">《${themeInfo.manual_name}》</a>。到此准备工作完成，可以开始 show 了。`,
                    desc: '完整的互动剧场演示流程如文档中所述。建议熟练掌握后再开始面向学生演示。',
                    imgs: [p21, p22],
                },
            ],
        ],
        "走进太空互动剧场": [
            [
                {
                    title: '打开高拍仪并点击按钮，调整到最大亮度。',
                    desc: '',
                    imgs: [p1],
                },
                {
                    title: '确保激光雷达插座开关、音响主机都处于开启状态（日常可以不用关）。',
                    desc: '',
                    imgs: [p2],
                },
                {
                    title: '用遥控器打开投影仪。按「Windows 徽标键 田+P」将投影模式设为「扩展」，直到在墙面上可以看到系统桌面的画面。',
                    desc: '',
                    imgs: [p3, p4, p5],
                },
            ],
            [
                {
                    title: '打开英荔自研工具<a href="adapterOpen://" target="_blank">「英荔 Adapter」</a>，开启后图标会显示在任务栏，而不会弹出任何界面。',
                    desc: '',
                    tips: `<div class="course-tips">
                    <div>点击上方的蓝色文字，Adapter 没有启动？可能是因为本机没有安装最新版的 Adapter，点击
                        <a href="https://ecourse.aimaker.space/%E6%95%99%E5%AD%A6%E8%BD%AF%E4%BB%B6%E7%AE%A1%E7%90%86/%E8%BD%AF%E4%BB%B6%E5%AE%89%E8%A3%85%E5%8C%85/Adapter_V2.3.0.exe" class="course-click">下载</a>。</div>
                    <div>互动投影目前只支持在 Windows10 1809 以上操作系统中运行，不会安装可以点击
                        <a target="_blank" href="https://1251349076.vod2.myqcloud.com/45e704edvodtransgzp1251349076/03b9a41f1397757896759347405/v.f40672.mp4" class="course-click">查看教程</a>。</div>
                </div>`,
                    imgs: [p6, p7],
                },
                {
                    title: '打开激光雷达软件「雷达模拟鼠标」，待开启完成后（一般会自动最小化）点开其主界面。',
                    desc: '',
                    imgs: [p8, p9, p10],
                },
                {
                    title: '此时触摸墙面，可以在激光雷达软件界面上看到相应反馈（显示你触碰的位置）。',
                    desc: '',
                    imgs: [p11, p12],
                },
            ],
            [
                {
                    title: `点击打开<a href="${themeInfo.work_url}" target="_blank">${themeInfo.work_name}</a>页面，待其加载完成，然后把作品标签页（一般是向右）拖动到墙上投影仪投出的画面中。`,
                    desc: '预计网页加载时间在 1 分钟以上，请耐心等待。',
                    imgs: [p13_os, p14_os, p15_os, p16],
                },

                {
                    title: '把浏览器设为全屏，然后把互动剧场作品设为全屏。',
                    desc: '',
                    imgs: [p17_os, p18_os, p19_os],
                },
                {
                    title: '点击绿旗，运行互动剧场作品（此时界面不会有任何反应，只是完成了初始化）。',
                    desc: '',
                    imgs: [p20_os],
                },
            ],
            [
                {
                    title: `点击打开<a href="${window.location.origin}/identify" target="_blank"> AI 识别涂色卡</a>页面。`,
                    desc: '需要授权允许网页使用摄像头的权限。',
                    imgs: [p24],
                },
                {
                    title: '从柜子里拿出涂色卡备用。',
                    desc: '涂色卡成本较高，建议每次不要拿太多涂色卡出来，避免损坏或遗失。每张涂色卡都有唯一编码，无法复印使用。如涂色卡即将用尽请联系英荔集团销售人员申请采购。',
                    imgs: [p25_os],
                },
                {
                    title: `首次使用，或是遇到了奇怪的问题可以看一下<a href="${themeInfo.manual_url}" target="_blank">《${themeInfo.manual_name}》</a>。到此准备工作完成，可以开始 show 了。`,
                    desc: '完整的互动剧场演示流程如文档中所述。建议熟练掌握后再开始面向学生演示。',
                    imgs: [p21_os],
                },
            ],
        ],
    }

    const result = JSON.parse(JSON.stringify(courseWriting[name]));
    console.log('result', result);
    // result.forEach((step: any) => {
    //     step.forEach((item: any) => {
    //         const abc = item.title.replace(new RegExp(`${themeInfo.work_name}`, 'g'), data.work_name);
    //         console.log('abc', abc);
    //     })
    // });

    return result
}

