<template>
    <a-config-provider :locale="locale">
        <Header></Header>
        <router-view />

        <div class="bottom" v-if="showBottomTip">
            <div class="bottom-upper" v-if="isLogin">
                <div v-if="isCoursePage">
                    <a-tooltip
                        title="首次使用这个产品吗？先看看教程吧"
                        :open="isFirst"
                        :getPopupContainer="getPopupContainer"
                    >
                        <span
                            class="bottom-text"
                            @click="handleLink(themeInfo.video_url)"
                            target="_blank"
                            >{{ themeInfo.video_name }}</span
                        >
                    </a-tooltip>
                    <span class="bottom-line"></span>
                </div>
                <span class="bottom-version">产品版本：{{ version }}</span>
            </div>
            <div class="bottom-lower">
                互动投影是使用图形化编程语言结合激光雷达实现的超大平面触控体验创客项目，可以在数平方米的平面上投射影像并支持多人互动，营造极具冲击力的沉浸式体验
            </div>
        </div>
        <EliteuServiceVue3 />
    </a-config-provider>
</template>
<script lang="ts">
import {
    defineComponent,
    onMounted,
    computed,
    reactive,
    ref,
    watch,
} from 'vue';
import Header from '@/components/Header.vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { EliteuServiceVue3 } from '@aimakerspace/eliteu-service-vue3';
import store from '@/store';
import { useVisitRecord } from '@/utils';
import { useRoute } from 'vue-router';
import { getVersion } from '@/api';
import router from './router';
export default defineComponent({
    name: 'IndexPage',
    components: {
        Header,
        EliteuServiceVue3,
    },
    data() {
        const isCoursePage = ref(false);
        const isLogin = ref(false);
        const showBottomTip = ref(true);
        const route = useRoute();
        const replace = () => {
            if (window.innerWidth < 601) {
                if (route.name !== 'mobile') {
                    router.replace({
                        name: 'mobile',
                    });
                }
            } else {
                if (route.name === 'mobile') {
                    router.replace({
                        name: 'theaters',
                    });
                }
            }
        };

        const campusInfo = reactive(store.state.campusInfo);
        const themeInfo = reactive(store.state.themeInfo);
        const { has: hasVisitRecord, save: saveVisitRecord } = useVisitRecord();
        // 是否首次使用
        const isFirst = computed(() => {
            const isHas =
                campusInfo.store_id && hasVisitRecord(campusInfo.store_id);
            return !isHas;
        });
        const handleLink = (link: string) => {
            campusInfo.store_id && saveVisitRecord(campusInfo.store_id);
            window.open(link, '_blank');
        };

        const getPopupContainer = () => {
            return document.querySelector('.bottom');
        };

        // 获取系统版本号
        const version = ref();
        onMounted(async () => {
            replace();
            window.addEventListener('resize', () => {
                replace();
            });
            const result = await getVersion();
            version.value = result.version;
        });

        watch(
            () => store.state.campusInfo,
            val => {
                isLogin.value = !!val.store_id;
            },
            {
                immediate: true,
            }
        );

        watch(
            [() => store.state.themeInfo, () => route.name],
            ([newThemeInfo, newRouteName]) => {
                themeInfo.video_name = newThemeInfo.video_name;
                themeInfo.video_url = newThemeInfo.video_url;
                isCoursePage.value = !!newThemeInfo.id;
                showBottomTip.value =
                    newRouteName !== 'identify' && newRouteName !== 'mobile';
            },
            {
                immediate: true,
            }
        );
        return {
            locale: zhCN,
            isFirst,
            themeInfo,
            handleLink,
            getPopupContainer,
            isCoursePage,
            showBottomTip,
            version,
            isLogin,
        };
    },
});
</script>

<style lang="scss">
@import url('./App.scss');

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    padding: 96px 0 0;
}

.link {
    width: 100%;
    position: fixed;
    bottom: 0;
    font-size: 14px;
    text-align: center;
    background: #fff;
    margin-bottom: 0;
    padding: 10px 0;

    a {
        color: var(--font-color-link);
    }
}

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0;
    min-height: 40px;
    width: 100%;
    background: #fff;
    text-align: center;
    font-size: 16px;
    box-shadow: 0px 1px 4px 0px rgba(172, 172, 172, 0.5);
    a {
        color: var(--font-color-link);
    }

    > div {
        cursor: pointer;
    }
    .bottom-text {
        height: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: var(--font-color-link);
        line-height: 16px;
        text-align: right;
        font-style: normal;
    }
    .bottom-upper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
    }
    .bottom-lower {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        text-align: center;
        font-style: normal;
        margin: 12px auto 10px;
    }
    .bottom-line {
        display: inline-block;
        width: 1px;
        height: 11px;
        background: #e9e9e9;
        border-radius: 1px;
        margin: 0 10px;
    }
    .bottom-version {
        height: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 16px;
        text-align: right;
        font-style: normal;
    }
}

@media screen and (max-width: 600px) {
    #app {
        padding-top: 18.4vw;
    }

    .eliteu-service-vue {
        .customer-service {
            width: 17.333vw;
            right: 5.6vw !important;
            bottom: 5.6vw !important;

            .chat {
                width: 17.333vw !important;
            }

            .content {
                width: 70.667vw;
                padding: 14.133vw 5.6vw 4.533vw;
                font-size: 3.733vw;
                color: #999999;
                line-height: 5.867vw;
                text-wrap: auto;

                .text {
                    text-align: justify;
                }

                .qr_code {
                    width: 32vw;
                    height: 32vw;
                }

                .waiter {
                    width: 32vw;
                    position: absolute;
                    top: -14.667vw;
                    right: 3.733vw;
                }

                .wechat {
                    margin-top: 4.533vw;
                }
            }
        }
    }
}
</style>
