<template>
    <div class="header">
        <img alt="英荔 AI 创造乐园" class="logo" src="../assets/logo.png" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'HeaderCom',
    props: {
        name: String,
    },
});
</script>

<style scoped lang="scss">
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 96px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
    z-index: 3;
    padding: 21px 148px;
    display: flex;
    align-items: center;

    .logo {
        width: 274px;
        height: 53px;
    }
    .container {
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
    .header {
        width: 100vw;
        height: 18.4vw;
        padding: 0 0 0 3.2vw;
        .logo {
            width: 55.2vw;
            height: 10.5vw;
        }
    }
}
</style>
